.header {
    background-color: var(--bg-offset);
    padding: 1em .2em;
    border-bottom: 1px solid var(--border);
    color: var(--text);

    h1 {
      font-size: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
}


/* search input */
.search-wrapper {
    margin: 48px 0;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 375px) {
  
    .search-input {
        width: 100%;
    }
    .search-wrapper {
        justify-content: start;
        flex-wrap: wrap;
    }
    .select {
        margin-top: 3.5em;

    }
}

.btn {
    background-color: var(--bg-offset);
    padding: .7em 2em 0.5em 1em;
    border: 1px solid var(--border);
    color: var(--text); 
    box-shadow: 0px 4px 6px var(--border);
    transition: all .4s cubic-bezier(0.215, 0.610, 0.355, 1);

    &:hover {
      box-shadow: 0px 0px 0px var(--border);

      svg {
        position: relative;
        right: 8px;
      }
    }
}
.search-input {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==');
    background-color: var(--bg-offset);
    background-size: 16px 16px;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding: 1.4em 2em;
    padding-left: 2.7em;
    border: 1px solid var(--border);
    color: var(--gray); 
    box-shadow: 0px 4px 6px var(--border);
    transition: all .4s cubic-bezier(0.215, 0.610, 0.355, 1);

    &:hover {
      box-shadow: 0px 0px 0px var(--border);
}
}
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none!important;
  // Additional resets for further consistency
  outline: none;
  background-color: var(--bg-offset);
  border-radius: 0.25em;
  border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  padding: 1.4em 2em 1.4em 1em;
  margin: 0;
  margin-right: 1em;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  width: 100%;  
  color: var(--gray); 

}
.select {
  min-width: 15ch;
  max-width: 30ch;
  cursor: pointer;
  line-height: 1.1;
  background-color: transparent;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 6px var(--border);
  transition: all .4s cubic-bezier(0.215, 0.610, 0.355, 1);

  &:hover {
    box-shadow: 0px 0px 0px var(--border);
}

  &::after {
    content: "";
    display: block;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--text);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
    margin-right: 1em;
  }
}
select,
.select:after {
  grid-area: select;
}
select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--text);
    border-radius: inherit;
  }

  .loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 6em 0;

    p {
      margin-top: 2em;
      text-align: center;
    }
  }
  .loading {
    animation:rotate-center 1s ease-in-out infinite forwards
  }
  

  /* dark mode */
.header-btn {
  color: var(--text);
  padding: .6em;
  

  svg {
   fill: white; 
   outline: currentColor;
  transition: all .4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}
@media (max-width: 375px) {
  .header-btn {

    h1 {
      max-width: 117px;
    }
  }
}
.header-btn.active {

  svg {
    fill: currentColor;
  }
}

  /* animations */
  @keyframes rotate-center{
    0%{
      transform:rotate(0)
    }
    100%{
      transform:rotate(360deg)
    }
  }

  /* footer */
  .footer {
    font-size:.875rem;
    padding:2rem 0;
    border-top:1px solid var(--border)
   }
   .footer a {
    color:inherit;
    text-decoration:underline
   }
   .footer__inner {
    display:-webkit-box;
    display:flex;
    flex-wrap:wrap;
    -webkit-box-align:center;
    align-items:center
   }
   .footer__item {
    flex-basis:50%;
    margin-bottom:.5rem
   }
   @media (min-width:670px) {
    .footer__inner {
     flex-wrap:nowrap
    }
    .footer__item {
     -webkit-box-flex:0;
     flex:0 0 auto;
     padding-right:2.5rem;
     margin:0
    }
    .footer__item--right {
     margin-left:auto;
     padding-right:0!important
    }
   }
   @media (min-width:940px) {
    .footer__item {
     padding-right:4rem
    }
   }