.card-grid {
    margin: 2em 0;
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
    grid-gap: 48px;
}
.card {
    background-color: var(--bg-offset);
    padding: 0px;
    box-shadow: 0px 2px 4px var(--border);
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: scale(1.1);

        .card-content {

            h2 {
                display: block;
                -webkit-line-clamp: none;
                -webkit-box-orient: none;
                overflow: visible;
            }
        }
    }
}
.card-image {
    max-height: 150px;


    img {
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.card-content {
    padding: 32px 15px;

    h2 {
        display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
}
.card-list {
    margin-top: 16px;

    li {
        color: var(--text);
        margin-top: 8px;

        span {
            color: var(--gray)
        }
    }
}

/* card view */
article {
    

    .article-main {
        margin-top: 2em
    }
}
@media (min-width: 600px) {
    article {
    display: grid;
    grid-gap: 10%;
    grid-template-columns: repeat( auto-fill, minmax(45%, 1fr) );

    .article-main {
        margin-top: 0em
    }
    }
}
@media (max-width: 375px) {
    
    .article-main {

        h3 {
            margin-top: 2em;
        }

        .flex {
            flex-wrap: wrap;

            .last-row {
                margin-top: 2em;
            }
        }
    }
    .article-end {
        flex-direction: row;
    }
}
.article-flag {
    max-width: 350px;

    img {
        box-shadow: 0px 2px 4px var(--border);
    }
}
.article-main {
    max-width: 390px;

        h3 {
            margin-bottom: 1em;
            font-size: 22px;
            font-weight: bolder;
        }
    }
.article-end {
    margin: 2em 0;
    flex-wrap: wrap;

    h4 {
        display: inline-block;
    }

    
    a {
        display: inline-block;
        margin: 2px;
        background-color: var(--bg-offset);
        padding: .4em .8em;
        border: 1px solid var(--border);
        font-size: 14px;
    }
}
.items {

    li {
        color: var(--text);
        margin-top: .9em;

        span {
            color: var(--gray);
        }
    }
}